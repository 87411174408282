.dark-theme {
  &.app-container,
  .ReactTable .-pagination .-btn {
    background: #0B1C3B;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff!important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #fff!important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #fff!important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #fff!important;
  }
  .app-footer {
    background: #62708a;
    border-color: #62708a;
  }
  .d-sidebar-base {
    background: #fff;
  }
  .d-sidebar .vertical-nav-menu .metismenu-container .metismenu-link.active {
    background: transparent!important;
  }
  .d-sidebar a,
  .d-sidebar-nav-main,
  .d-sidebar-sub-nav,
  .d-sidebar-title {
    color: #0b1c3b !important;
  }
  .d-tab,
  .d-header-subtitle,
  .breadcrumb-item.active,
  .page-title-heading,
  .ReactTable .-pagination .-pageInfo,
  .ReactTable .rt-tbody .rt-td,
  .ReactTable .rt-thead .rt-th,
  .card-title,
  label {
    color: #fff!important;
  }
  .ReactTable .rt-thead.-header {
    background: #8699b9;
  }
  .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 #ffffff;
  }
  .breadcrumb,
  .breadcrumb-item + .breadcrumb-item::before {
    color: #20baec;
  }
  .card {
    background: #66748e;
  }
  .ReactTable .rt-tbody .rt-tr-group,
  .ReactTable .rt-tbody .rt-td,
  .ReactTable .rt-thead .rt-th,
  .ReactTable .rt-thead .rt-td,
  .ReactTable {
    border-color: #8497b9;
  }
  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(12, 27, 57, 0.3)
  }
  .ReactTable .-pagination input, .ReactTable .-pagination select, .ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
    background: rgba(255, 255, 255, 0.21)!important;
    border-color: #8593b1;
    color: #fff;
  }
  .form-control {
    background-color: #8599b9;
    border: 1px solid #8599b9;
    color: #fff;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.73);
      background-color: #899fc1;
      border-color: #899fc1;
    }
  }
  .rw-widget-container {
    background-color: #8599b9!important;
    border: 1px solid #8599b9!important;
  }
  .rw-state-focus .rw-widget-container,
  .rw-open .rw-widget-container  {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.75)!important;
  }
  .main-card > .card-body > .card-title {
    border-bottom: #8499b9 solid 1px;
  }
  .main-card > .card-body > .card-title::before {
    background: #fff;
  }
  .training-stat {
    .text-focus,
    .text-danger {
      color: #e0f7ff!important;
    }
  }

  .text-danger,
  .dropzone-content,
  .d-dropzone {
    color: #fff!important;
  }
  .d-react-select {
    .css-vj8t7z,
    .css-2o5izw,
    .css-162g8z5 {
      background-color: #8499b8!important;
      border-color: #8499b8!important;
    }
    .css-xp4uvy,
    .css-1ep9fjw,
    .css-1492t68,
    .css-142w6gm {
      color: #fff;
    }
    .css-2o5izw {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.73);
    }
    .css-15k3avv {

    }
  }
  .d-device,
  .d-connect-tv,
  .table {
    color: #fff;
  }
  .rbc-btn-group button {
    color: transparent!important;
  }
  .rbc-off-range-bg {
    background: #57657f;
  }
  .rbc-off-range,
  .rbc-date-cell,
  .rbc-header,
  .rbc-toolbar-label,
  .rbc-label {
    color: #fff;
  }
  .d-calendar .rbc-time-view, .d-calendar .rbc-month-view,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-view .rbc-header,
  .rbc-month-row + .rbc-month-row,
  .rbc-month-view .rbc-header + .rbc-header,
  .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot {
    border-color: #8599b9;
  }
  .d-widget {
    background: #576884;
    color: #fff!important;
  }
  .d-widget-subtitle,
  .d-widget-title,
  .d-widget-title-small,
  .d-score-title {
    color: #fff!important;
  }
  .d-widget-lightgrey.d-widget-solid {
    background: rgba(142, 153, 171, 0.45)
  }
  .d-widget-greyblue .d-widget-list,
  .d-widget-green .d-widget-item-grey,
  .d-range-min,
  .d-range-max,
  .d-category-option {
    color: #fff;
  }
  .d-list-item-mod {
    color: #87a4d6;
  }
  .d-graph-wrapper,
  .d-my-workouts,
  .d-widget-wrapper {
    background: #203250;
    color: #fff!important;
  }
  .d-training-info-desc,
  .d-training-sup-desc {
    color: #d2d2d2;
  }
  .d-my-workouts .rt-th {
    background: #576884!important;
  }
  .d-my-workouts .rt-resizable-header-content,
  .d-chart-overlay,
  .d-training-info-group,
  .d-chart-info-main-text {
    color: #fff;
  }
  .d-workout-actions-button {
    background: #566c92;
  }
  .d-workout-actions-search input {
    background: transparent;
  }
  .d-training-option img {
    width: 16px;
    height: 16px;
  }
  .d-calendar-option-add img {
    width: 20px;
    height: 20px;
  }
  .dropdown-menu {
    background-color: #465977;
  }
  .dropdown-menu .dropdown-item {
    color: #fff;
  }
  .create-training .col-md-1 {
    color: #fff;
  }
  .d-studio-calendar {
    .rbc-toolbar {
      .rbc-toolbar-label {
        color: #fff;
      }
    }
    .rbc-time-slot .rbc-label {
      color: #fff
    }
  }
  .d-subtitles-language-toggle {
    border-color: #d3d8de;
    background: #d3d8de;
    outline: none!important;
    &:focus {
      border-color: #fff;
      box-shadow: 0 0 0 0.2rem rgba(255,255,255,1);
    }
  }

}
