// Header Base

.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 $layout-spacer-x;
    height: $app-header-height;

    .app-header-left {
      display: flex;
      align-items: center;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width 0.2s;

  .logo-src {
    height: $logo-height;
    width: $logo-width;
    background: url("~assets/utils/images/b24ImageDark.png");
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

.d-header-minilogo {
  display: none;
}

.d-header-hamburger-icon {
  display: none;
}

@media (max-width: 991px) {
  .app-header.bg-lightblue {
    background: #0b1c3b !important;
  }
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";

.d-header-bottom {
  display: none !important;
  font-family: "Roboto", sans-serif;
}
.d-header:not(.user-header),
.d-header:not(.d-header-fixed) {
  width: 60%;
  margin-left: 40%;
}
.user-header {
  width: 100%!important;
  margin-left: 0!important;
}

@media (min-width: 1001px) {
  .d-header-fixed {
    background: #fff;
    position: fixed;
    width: calc(100% - 240px)!important;
    right: 0;
    top: 0;
    z-index: 3000;
  }
}

@media (max-width: 1000px) {
  .d-header:not(.user-header) {
    width: unset;
    margin-left: 0;
    .d-header-bottom {
      display: none;
    }
  }
  .d-header-hamburger {
    height: 70px;
    width: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;
  }
  .d-header-hamburger__open {
    background-color: #3b3a53;
  }
  .d-header-hamburger-icon {
    display: block;
  }
  .d-header-minilogo {
    display: block;
  }
  .d-header-title {
    display: none;
    font-size: 17px !important;
  }
  .d-header-bottom {
    // display: flex !important;
    // justify-content: center;
    // color: #fff;
  }
  .d-header-tab {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .d-header {
    a {
      color: #fff !important;
      text-decoration: none !important;
    }
    background: #43425d;
    padding-left: 15px;
    padding-right: 15px;
    .d-header-top,
    .d-header-title {
      color: #fff;
    }
    .d-header-middle {
      margin-top: -25px;
    }
  }
}
@media (max-width: 500px) {
  .d-header-title {
    font-size: 14px !important;
  }
}

@media (max-width: 360px) {
  .d-header-title {
    font-size: 12px !important;
  }
}
